import { useEffect, useState } from "react";
import { useEnvironmentContext } from "../../app/EnvironmentDataProvider";
import { useCurrentLoginLogo } from "../../app/hooks/gyarados.hook";
import { useStore } from "../../app/store";
import { useIsSmallScreen } from "../../common/hooks/ui";
import { getImageUrl } from "../../common/util/gyarados.util";
import Hide from "../../componentsLibrary/atoms/Hide";
import { useText } from "../language/language.hook";
import LoginPageUi, { PropsLoginPageUi } from "./LoginPageUi";
import { LoginLinks } from "./component/LoginLinks";
import { LoginLogo } from "./component/LoginLogo";
import useAuthCheckStep from "./steps/useAuthCheckStep";
import useAuthManualCheckStep from "./steps/useAuthManualCheckStep";
import useBrowserCheckStep from "./steps/useBrowserCheckStep";
import useInstanceAvailabilityCheckStep from "./steps/useInstanceAvailabilityCheckStep";
import useLoginIntroStep from "./steps/useLoginIntroStep";
import useLoginIsDoneStep from "./steps/useLoginIsDoneStep";
import useOpeningTimesCheckStep from "./steps/useOpeningTimesCheckStep";
import useQueueCheckStep from "./steps/useQueueCheckStep";
import useStartActionCheckStep from "./steps/useStartActionCheckStep";

type Props = {
  onDone?: () => void;
};

const LoginPage: React.FC<Props> = ({ onDone }) => {
  const t = useText();
  const isSmallScreen = useIsSmallScreen();
  const { environment } = useEnvironmentContext();
  const isLoginIntroStepDone = useLoginIntroStep();
  const currentStep = useStore((s) => s.userFlow.currentStep);
  const completeStep = useStore((s) => s.userFlow.completeStep);
  const isStepReached = useStore((s) => s.userFlow.isStepReached);
  const isStepCompleted = useStore((s) => s.userFlow.isStepCompleted);
  const selectedLocaleCode = useStore((s) => s.i18n.selectedLocaleCode);
  // this is common for all steps
  const [loginPageProps, setLoginPageProps] = useState<PropsLoginPageUi>();
  const openPanel = useStore((s) => s.layout.openPanel);

  const startButtonVisible = useStore(
    (s) => s.layout.panels.startButton.visible
  );

  const startButtonVisibility = useStore(
    (s) => s.layout.panels.startButton.visible
  );

  const logo = useCurrentLoginLogo();

  const renderLoginLinks = (props?: { absolute?: boolean }) => (
    <LoginLinks
      selectedLocaleCode={selectedLocaleCode}
      locales={environment.locales}
      links={environment.onboardingLinks}
      openPanel={openPanel}
      isAuthenticated={isStepCompleted("login:auth-manual")}
      isPanel
      absolute={props?.absolute}
    />
  );

  // We only want to show the logo when the start button is visible and we are not in the start action step
  const logoUrl = getImageUrl(logo, 200, 200);

  const [userEarlyClick, setUserEarlyClick] = useState(false);
  // The login part is broken into series of substeps, each of them
  // checking for a specific condition to be met.
  // They all have their own hook
  // login:check-browser
  useBrowserCheckStep(setLoginPageProps);
  // login:auth-check
  useAuthCheckStep(setLoginPageProps);
  // login:check-opening-times
  useOpeningTimesCheckStep(setLoginPageProps);
  // login:auth-manual
  const { dialog: authDialog } = useAuthManualCheckStep(
    setLoginPageProps,
    renderLoginLinks
  );
  // login:availability
  useInstanceAvailabilityCheckStep(setLoginPageProps);
  // login:queue-and-connect
  useQueueCheckStep(setLoginPageProps, userEarlyClick);
  // login:start-action
  useStartActionCheckStep(
    setLoginPageProps,
    userEarlyClick,
    startButtonVisibility
  );
  // experience:ready
  const { isLoginDone: isDone } = useLoginIsDoneStep(setLoginPageProps);

  // When the login process is done we need to animate it gracefully away, before
  // emitting onDone which will umount it.
  useEffect(() => {
    let id: undefined | number;
    if (isDone) id = window.setTimeout(() => onDone?.(), 1000);
    return () => {
      if (id) clearTimeout(id);
    };
  }, [isDone, onDone]);

  const onMainButtonClick = () => {
    // Main action button has multiple functionalities depending on which moment
    // it is clicked. So, we have to check in which step are we AND if the start button is visible
    if (currentStep === "login:start-action" && startButtonVisible) {
      completeStep("login:start-action");
    } else {
      setUserEarlyClick(true);
    }
  };

  return (
    <>
      <Hide
        hide={isDone || !isLoginIntroStepDone}
        speed={800}
        width="100dvw"
        height="100dvh"
      >
        <LoginPageUi
          mainButtonLabel={t("login_generic_call_to_action")}
          onMainButtonClick={onMainButtonClick}
          {...loginPageProps}
        />
      </Hide>
      {authDialog}
      <LoginLogo
        logoUrl={logoUrl}
        showLogo={startButtonVisible && !isStepReached("login:start-action")}
      />
      {(!isSmallScreen || !authDialog) && (
        <LoginLinks
          selectedLocaleCode={selectedLocaleCode}
          locales={environment.locales}
          links={environment.onboardingLinks}
          openPanel={openPanel}
          isAuthenticated={isStepCompleted("login:auth-manual")}
        />
      )}
    </>
  );
};

export default LoginPage;
